<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="기계/장비 기본정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <div v-if="editable && tabParam.plantName" class="col-4">
            <c-label-text title="공사현장" :value="tabParam.plantName"></c-label-text>
          </div>
          <div v-if="editable && tabParam.processName" class="col-4">
            <c-label-text title="공종" :value="tabParam.processName"></c-label-text>
          </div>
          <div v-if="editable && tabParam.vendorName" class="col-4">
            <c-label-text title="업체" :value="tabParam.vendorName"></c-label-text>
          </div>
          <div v-if="editable && tabParam.facilityTypeName" class="col-4">
            <c-label-text title="기계분류" :value="tabParam.facilityTypeName"></c-label-text>
          </div>
          <div v-if="editable && tabParam.facilityName" class="col-4">
            <c-label-text title="기계명" :value="tabParam.facilityName"></c-label-text>
          </div>
          <div v-if="editable && tabParam.deviceNo" class="col-4">
            <c-label-text title="기기번호" :value="tabParam.deviceNo"></c-label-text>
          </div>
        </template>
      </c-card>
      <c-table
        ref="table"
        title="점검 이력 목록"
        tableId="hazardEquipmentHis"
        :columns="grid.columns"
        :data="tabParam.machineryHistoryModelList"
        :usePaging="false"
        :checkClickFlag="false"
        :isExcelDown="false"
        :merge="grid.merge"
        selection="multiple"
        rowKey="saiContructFacilityHistoryId"
        @linkClick="linkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <c-btn v-if="editable" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import transactionConfig from "@/js/transactionConfig";
export default {
  name: 'hazard-equipment-history',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        saiContructFacilityId: '',
        facilityTypeCd: '',
        plantCd: '',
        plantName: '',
        processName: '',
        vendorCd: '',
        vendorName: '',
        facilityTypeName: '',
        facilityName: '',
        deviceNo: '',
        managerNo: '',
        checkValidPeriod: '',
        machineryHistoryModelList: [],
        deleteMachineryHistoryModelList: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: "inspectionUserName",
            field: "inspectionUserName",
            label: "점검자",
            style: 'width:120px',
            align: "center",
            sortable: false,
          },
          {
            name: "inspectionDate",
            field: "inspectionDate",
            label: "점검일",
            style: 'width:120px',
            align: "center",
            type: 'link',
            sortable: false,
          },
          {
            name: "inspectionResultDetail",
            field: "inspectionResultDetail",
            label: "특이사항",
            style: 'width:350px',
            align: "left",
            sortable: false,
          },
        ],
        height: '400px',
        data: [],
      },
      isSave: false,
      getUrl: '',
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.deleteUrl = transactionConfig.sop.min.machinery.delete.result1.url;

    },
    addItem() {
      this.linkClick();
    },
    linkClick(result) {
      this.popupOptions.title = '기계/장비 이력 상세';
      this.popupOptions.target = () => import(`${'./hazardEquipmentHistoryResult.vue'}`);
      this.popupOptions.param = {
        saiContructFacilityHistoryId: result ? result.saiContructFacilityHistoryId : '', 
        facilityTypeCd: this.tabParam.facilityTypeCd,
        saiContructFacilityId: this.tabParam.saiContructFacilityId,
        checkValidPeriod: this.tabParam.checkValidPeriod,
        vendorCd: this.tabParam.vendorCd,
        plantCd: this.tabParam.plantCd
      } 
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closeItemPopup;
    },
    closeItemPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('getDetail')
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.tabParam.hazardEquipmentHistoryModels = this.$_.reject(this.tabParam.hazardEquipmentHistoryModels, { hhmHazardousMachineryId: item.hhmHazardousMachineryId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('getDetail')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
  
  }
};
</script>